import React, { Component } from 'react';
import './index.css'
import Div100vh from 'react-div-100vh'
import Content from './components/content';
import UserInfo from './UserInfo'

class App extends Component {
  render() {
    return (
	  <Div100vh>
        <Content UserInfo={UserInfo}/>
      </Div100vh>
    );
  }
}

export default App;
