import React, { Component } from 'react';
import logo from '../yay.svg';

export default class Content extends Component {
  render() {
    let UserInfo = this.props.UserInfo;
    return (
      <div className="content">
      	<title>Yay! You found {UserInfo.firstName}’s Notebook!</title>
		<img src={logo} alt="Yay" />
		<h1>You found me!</h1>
		<p>That’s great, but what’s next?</p>
		<p>Just contact my owner at this address and {UserInfo.pronoun}’ll work something out:</p>
		<p className="address">
			<a href={"mailto:" + UserInfo.address}>{UserInfo.address}</a>
		</p>
		<p className="thanks">Thank you!</p>
		<div className="gradient1"></div>
		<div className="gradient2"></div>
		<div className="gradient3"></div>
	</div>
    );
  }
}
